<template>
  <div id="header-home-top">
    <div class="lg-container">
      <div class="row pl-20 flex">
        <router-link to="/" class=" pull-left hidden-xs hidden-sm"><h1 class="logo ml-10 mt-10"></h1></router-link>
        <div class="flex whitespace-no-wrap md:pr-0 mt-2 margin-left-auto ">
          <home-user-top-desktop></home-user-top-desktop>
          <home-user-top-mobile></home-user-top-mobile>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    HomeUserTopMobile: () => import('@/components/home/HomeUserTopMobile'),
    HomeUserTopDesktop: () => import('@/components/home/HomeUserTopDesktop')
  }
}
</script>
